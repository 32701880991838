import { useState } from 'react';
import { Modal, Popover } from 'antd';

import PowerBIReport from '../PowerBI/PowerBI';
import Calendar from '../Calendar/Calendar';
import Videos from '../Videos/Videos';
import Calculator from '../Calculator/Calculator';
import Presence from '../Presence/Presence';
import LegalDoc from './LegalDoc/LegalDoc';
import InvestmentCommitment from './InvestmentCommitment/InvestmentCommitment';

import useUserDocuments from '../../hooks/useUserDocuments';

import { IHome } from './IHome';

import 'antd/dist/reset.css';
import './Home.scss';
import DocumentsAPI from '../../api/DocumentsAPI';
import Footer from '../NavBar/Footer/Footer';
import NavBar from '../NavBar/NavBar';
import SectionBlock from './SectionBlock/SectionBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEnvelope, faFileLines, faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight, faAnglesRight } from '@fortawesome/free-solid-svg-icons';

const Home = ({ userInstitutionName, userIdInstitution, baseUrl, getAuthToken, showServiceFeedbackModal, userLogo, logout }: IHome) => {
	const [modalVisorPDFVisible, setModalVisorPDFVisible] = useState(false);
	const [calendarioVisible, setCalendarioVisible] = useState(false);
	const [PowerBIVisible, setPowerBIVisible] = useState(false);
	const [PowerBICashflowVisible, setPowerBICashflowVisible] = useState(false);
	const [formacionPEVisible, setFormacionPEVisible] = useState(false);
	const [urlActiveDocument, setUrlActiveDocument] = useState("");
	const [modalGaleriaVideosVisible, setModalGaleriaVideosVisible] = useState(false);
	const [modalCalculatorVisible, setmodalCalculatorVisible] = useState<boolean>(false);

	const documentsAPI = DocumentsAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal
	})
	const { documents } = useUserDocuments({ api: documentsAPI, userInstitutionName });

	const showDocumentViewer = (urlDocument: string) => {
		setUrlActiveDocument(urlDocument);
		setModalVisorPDFVisible(true);
	};

	const documentationOptions = [
		<Popover 
			className="documentation-popover"
			placement="bottomLeft"
			trigger="hover"
			content={
				<>{documents && documents
					.map(document => (
						 <div className='itemMenu' onClick={() => showDocumentViewer(`${document.previewUri}#toolbar=0`)}>
							{document.name}
							</div>
				))}</>
			}>
			<div className="section-btn">
				<p>Documentación</p> 
				<FontAwesomeIcon icon={faAngleRight} />
			</div>
		</Popover>,
		/* VIDEOS */
		<button className="section-btn" onClick={() => setModalGaleriaVideosVisible(true)}>
			<p>Galería de videos</p> 
			<FontAwesomeIcon icon={faAngleRight} />
		</button>,
		/* FORMACION PE */
		<button className="section-btn" onClick={() => setFormacionPEVisible(true)}>
			<p>Formación PE</p> 
			<FontAwesomeIcon icon={faAngleRight} />
		</button>
	]

	const cashflowOptions = [		
		<button className="section-btn" onClick={() => setPowerBIVisible(true)}>
			<p>Gestión de cartera</p> 
			<FontAwesomeIcon icon={faAngleRight} />
		</button>
	]

	const contratacionOptions = [
		<button className="section-btn" onClick={() => window.open('https://bookbuilding.qualitasfunds.com/', '_blank')}>
			<p>Contratar ahora</p> 
			<FontAwesomeIcon icon={faAngleRight} />
		</button>,
		<button className="section-btn" onClick={() => setmodalCalculatorVisible(true)}>
			<p>Calculadora ecualización</p> 
			<FontAwesomeIcon icon={faAngleRight} />
		</button>,
		<Popover 
			placement="bottomLeft"
			content={
				<InvestmentCommitment
					showDocumentViewer={showDocumentViewer}
					userIdInstitution={userIdInstitution}
					baseUrl={baseUrl}
					getAuthToken={getAuthToken}
					showServiceFeedbackModal={showServiceFeedbackModal}
				/>
			}
			trigger="hover"
		>
			<div className="section-btn">
				<p>Compromiso de inversión</p> 
				<FontAwesomeIcon icon={faAngleRight} />
			</div>
		</Popover>,
		<Popover
			placement="bottomLeft"
			content={
				<LegalDoc
					showDocumentViewer={showDocumentViewer}
					userIdInstitution={userIdInstitution}
					baseUrl={baseUrl}
					getAuthToken={getAuthToken}
					showServiceFeedbackModal={showServiceFeedbackModal}
				/>
			}
			trigger="hover"
		>
		<div className="section-btn">
			<p>Documentación legal</p> 
			<FontAwesomeIcon icon={faAngleRight} />
		</div>
		</Popover>
	]

	const contactOptions = [
		<Presence />,
		<a href="mailto:investor.relations@qualitasfunds.com" className="section-btn">
			<p>Envía un email</p>
			<FontAwesomeIcon icon={faAngleRight} />
		</a>,
	]

	return (
		<div>
			<div className="home-qf">
				<NavBar 
					userLogo={userLogo}
					logout={logout}
				/>
				<div className="support-content">
					<div className="title">
						<p>
							Expertos en <br />
							<span>
							<FontAwesomeIcon icon={faAnglesRight} />
							</span>
							<strong> lower mid-market</strong>
						</p>
					</div>
					<div className="sections-grid">
						<SectionBlock 
							options={documentationOptions} 
							icon={faFolder} 
							title="Documentación comercial y formación"
						/>
						<SectionBlock 
							options={cashflowOptions} 
							icon={faMoneyBill1} 
							title="Estimación de flujos y desembolsos"
						/>
						<SectionBlock 
							options={contratacionOptions} 
							icon={faFileLines} 
							title="Contratación"
						/>
						<SectionBlock 
							options={contactOptions} 
							icon={faEnvelope} 
							title="Contacto"
						/>
					</div>
				</div>
			</div>
			<Footer />
				<Modal
					title=""
					open={modalVisorPDFVisible}
					onOk={() => setModalVisorPDFVisible(false)}
					onCancel={() => setModalVisorPDFVisible(false)}
					width={"1200px"}
					centered
					footer={[]}
				>
					<iframe id="fred" title="PDF in an i-Frame" src={urlActiveDocument} scrolling="auto" height="600" width="100%" ></iframe>
				</Modal>

				<Modal
					title={"Formación PE"}
					open={formacionPEVisible}
					onOk={() => setFormacionPEVisible(false)}
					onCancel={() => setFormacionPEVisible(false)}
					width={"80%"}
					centered
					footer={[]}
				>
					<iframe id="PE" title="PDF in an i-Frame" src="https://qualitasfunds.com/private-equity/#que-es-private-equity" scrolling="auto" height="600" width="100%" ></iframe>
				</Modal>
				<Calendar calendarioVisible={calendarioVisible} setCalendarioVisible={setCalendarioVisible} />
				<PowerBIReport
					PowerBIVisible={PowerBIVisible}
					setPowerBIVisible={setPowerBIVisible}
					PowerBiId="0ef5a196-3f57-49cf-b003-ee0511a67192"
					baseUrl={baseUrl}
					getAuthToken={getAuthToken}
					showServiceFeedbackModal={showServiceFeedbackModal}
				/>
				<PowerBIReport
					PowerBIVisible={PowerBICashflowVisible}
					setPowerBIVisible={setPowerBICashflowVisible}
					PowerBiId="a0ca36a0-57ed-461c-937c-52d0c4440184"
					baseUrl={baseUrl}
					getAuthToken={getAuthToken}
					showServiceFeedbackModal={showServiceFeedbackModal}
				/>
				<Videos
					api={documentsAPI}
					modalGaleriaVideosVisible={modalGaleriaVideosVisible}
					setModalGaleriaVideosVisible={setModalGaleriaVideosVisible}
				/>
				<Calculator
					modalCalculatorVisible={modalCalculatorVisible}
					setModalCalculatorVisible={setmodalCalculatorVisible}
					userInstitutionName={userInstitutionName}
				/>
		</div >
	);
}

export default Home;